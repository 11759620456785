// @ts-strict-ignore
import yup from '~/utils/validationMessages'

import { gt } from '~/locale'

export const modalProps = {
  closeIconPosition: 'inside' as const,
  fillScreenOnMobile: false,
  size: 'small' as const,
  hasVisibleOverlay: true,
  hasClickableOverlay: false,
  handleClick: () => null,
  position: 'fixed' as const,
  iconSize: 'big' as const,
}

export const loginModalProps = {
  hasVisibleOverlay: true,
  hasClickableOverlay: false,
  closeIconPosition: 'outside' as const,
  fillScreenOnMobile: true,
  handleClick: () => null,
  position: 'fixed' as const,
  size: 'big' as const,
  iconSize: 'big' as const,
}

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .test({
      name: 'no_spaces',
      message: gt.tp('ValidationError', 'This field cannot contain spaces'),
      test: value => value && !value.match(/\s/g),
    })
    .matches(/^(?=.*[a-z])(?=.*[A-Z]).*$/, gt.tp('ValidationError', 'This field must contain a mix of uppercase and lowercase characters'))
    .min(8)
    .required(),
  passwordRepeat: yup
    .string()
    .oneOf([yup.ref('password'), null], gt.tp('ValidationError', 'Passwords must match'))
    .required(),
})
