import { Form } from 'formik'

import styled from '@emotion/styled'

import { StyledForgotPasswordModal } from '../ForgotPasswordModal/ForgotPasswordModalShards'

export const StyledSetNewPasswordModal = styled(StyledForgotPasswordModal)``

export const StyledNewPasswordForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 0.75rem;
  & > *:last-child {
    margin-top: 0.5rem;
  }
`

export const StyledToastContainer = styled.div`
  width: 100%;
  & > * {
    margin: -1rem 0;
  }
`
