import { Heading3 } from '~/theme/utils/typography'

import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'

import { StyledPasswordChangedModal } from './PasswordChangedModalShards'

interface PasswordChangedModalViewProps {
  handleClick: () => void
}

const PasswordChangedModalView = ({ handleClick }: PasswordChangedModalViewProps) => {
  return (
    <StyledPasswordChangedModal>
      <Heading3 fontWeight='semiBold'>{gt.tp('CheckYourEmailModal', 'Your password has been changed!')}</Heading3>
      <Button
        label={gt.tp('ForgotPasswordModal', 'Log in')}
        color='green'
        width='full'
        height='big'
        fontWeight='regular'
        onClick={handleClick}
      />
    </StyledPasswordChangedModal>
  )
}

export default PasswordChangedModalView
